import SanityMuxPlayer from "sanity-mux-player"
import React, { useRef, useState } from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import * as styles from "./styles.module.scss"
import { AiOutlinePlayCircle } from "react-icons/ai"
import { ReactComponent as Svg } from "./line.svg"
import { ReactComponent as RightSVG } from "@static/assets/logo-right.svg"
import Video from "@components/shared/video"

import { FaPlay } from "react-icons/fa"
// import GrowthSvg from "../../growthSVG"
import SvgLine from "../../reusables/svgLine"

import "./videoPlayer.sass"

// Import Cta Buttons
// import CtaButton from "../../global/ctaIllustration/CtaButton"
import MyLink from "../../myLink"

const HomeVideoCta = props => {
  const { sanityFrontPage: data } = useStaticQuery(graphql`
    query bannerVideoQuery {
      sanityFrontPage {
        homeBannerVideo {
          backgroundColorVideoBanner
          video {
            asset {
              _key
              _type
              playbackId
              thumbTime
              status
              filename
              assetId
            }
          }
          videoCta {
            ctaText
            subTitle
            title
            ctaButtons {
              ... on SanityLink {
                _key
                _type
                internallink
                linktext
                newwindow
                url
              }
            }
          }
          vimeo {
            url
            image {
              asset {
                gatsbyImageData
              }
            }
            videoTitle
          }
        }
      }
    }
  `)

  // Use State to target play button
  const [showPlayBtn, setShowPlayBtn] = useState(true)
  const muxVideoPlayer = useRef(null)

  const playVideo = () => {
    setShowPlayBtn(false)
    muxVideoPlayer.current.handleVideoClick()
    muxVideoPlayer.current.video.current.play()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.svgMax}>

        <div className={styles.contentWrapper}>
          <div className={styles.wrapper_inner}>
            <div
              style={{
                backgroundColor:
                  data.homeBannerVideo.backgroundColorVideoBanner,
              }}
              className={styles.textCont}
            >
              <div className={styles.textInner}>
                {data.homeBannerVideo.videoCta.subTitle ? (
                  <p>{data.homeBannerVideo.videoCta.subTitle}</p>
                ) : (
                  ""
                )}
                
                {data.homeBannerVideo.videoCta.title ? (
                  <h2>{data.homeBannerVideo.videoCta.title}</h2>
                ) : (
                  ""
                )}

                {data.homeBannerVideo.videoCta.ctaText ? (
                  <p>{data.homeBannerVideo.videoCta.ctaText}</p>
                ) : (
                  ""
                )}

                {data.homeBannerVideo.videoCta.ctaButtons.map(button => {
                  return <MyLink {...button} className={styles.button} />
                })}
              </div>
            </div>
            <div className={styles.imgCont}>
              {data.homeBannerVideo.vimeo && (
                <Video
                  url={data.homeBannerVideo.vimeo.url}
                  thumbnail={data.homeBannerVideo.vimeo.image}
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomeVideoCta
