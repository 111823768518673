// Core
import React, { useEffect, useContext } from "react"
import axios from "axios"
import { useStaticQuery, graphql } from "gatsby"

import { LocationsContext } from "../contexts/locationsContext"

// Components
import Layout from "@components/layout"
import SEO from "@components/home/seo"
import PodCards from "@components/home/podCards"
import Hero from "@components/shared/hero"
import FirstCtaIllustration from "@components/home/firsrtCtaIllustration"
import HomeFeaturedLogos from "@components/home/featuredLogos"
import SecondCtaIllustration from "@components/home/secondCtaIIlustration"
import CtaPhotoCards from "@components/home/CtaPhotoCards"
import HomeVideoCta from "@components/home/videoCta"
// import FeaturedText from "@components/home/featureText"
import BlogFeed from "@components/home/blogsFeed"

import SplitPanel from "@components/home/splitPanel"

// Styles
import "../global-styless/global.scss"
import "./homePage.scss"

const IndexPage = () => {
  const { ipAddress } = useContext(LocationsContext)

  useEffect(() => {
    handleFacebookConversionapi()
  }, [ipAddress])

  const handleFacebookConversionapi = () => {
    //Send request to netlify functions

    let body = {
      client_user_agent: navigator.userAgent,
      client_ip_address: ipAddress,
    }

    axios
      .post("/.netlify/functions/homepageView", body)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  const { sanityFrontPage } = useStaticQuery(graphql`
    {
      sanityFrontPage {
        homeHero {
          richText {
            _rawColumnContent
          }
          heroImage {
            ...sanityFullWidthImage
          }
          startHex
          endHex
          ctaButtons {
            _key
            _type
            internallink
            kind
            linkDisplay
            linktext
            newwindow
            url
            buttonDisplay
          }
        }
      }
    }
  `)

  return (
    <Layout className="home-page">
      <SEO />
      <div id="home_page">
        <Hero hero={sanityFrontPage.homeHero} />
        <SplitPanel />
        <HomeFeaturedLogos />
        <HomeVideoCta />
        {/* <FeaturedText /> */}
        <PodCards />
        <FirstCtaIllustration />
        <SecondCtaIllustration />
        <CtaPhotoCards />
        <BlogFeed />
      </div>
    </Layout >
  )
}

export default IndexPage
