import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedLogos from "../featuredLogos"
import styled from "styled-components"

import { ReactComponent as Svg } from "./line7.svg"
import SvgLine from "../reusables/svgLine"

const LogosContainer = styled.div`
  position: relative;
`

const HomeFeaturedLogos = () => {
  const {
    sanityFrontPage: { featuredLawFirm: data },
  } = useStaticQuery(graphql`
    query homeFeaturedLawFirmQuery {
      sanityFrontPage {
        featuredLawFirm {
          logos {
            title
            logoImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            linkPath
          }
          title
        }
      }
    }
  `)

  return (
    <LogosContainer>
      {/* <SvgLine top="35px" right="0px">
          <Svg/>
        </SvgLine> */}
      <FeaturedLogos data={data} />
    </LogosContainer>
  )
}

export default HomeFeaturedLogos
