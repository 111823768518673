import React from "react"
import * as styles from "./drawing.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { v4 as uuid } from "uuid"
import { Container } from "@util/standard"

// import Image from "gatsby-image"

// Functional CTa Illustration Comp
import CtaIllustration from "../../global/ctaIllustration"
import CtaButton from "../../global/ctaIllustration/CtaButton"
import CtaText from "../../global/ctaIllustration/CtaText"
import SideImage from "../../global/ctaIllustration/SideImage"
import MyLink from "../../myLink"

import SvgLine from "../../reusables/svgLine"

// Animated SVGs
import { ReactComponent as Svg } from "./rightSvg.svg"
import { ReactComponent as SvgSecond } from "./leftLine.svg"

import styled from "styled-components"

const StyledSVG = styled(Container)`
  ${({ startHex, endHex }) =>
    startHex &&
    endHex &&
    `background: linear-gradient(95.93deg,${startHex}, ${endHex});`};

  margin-top: -20px;

  svg {
    width: 100%;
  }
`

const FirstCtaIllustration = () => {
  // Data
  const { sanityFrontPage: data } = useStaticQuery(graphql`
    query drawingCtaQuery {
      sanityFrontPage {
        firstHomeIllustrationCtaBanner {
          backgroundColorIllustrationBannerChoose
          ctaIllustration {
            alt
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          ctaTextIllustration {
            ctaText

            title
            subTitle
            ctaButtons {
              ... on SanityLink {
                _key
                _type
                internallink
                kind
                linkDisplay
                linktext
                newwindow
                url
                buttonDisplay
              }
            }
          }
        }
      }
    }
  `)

  return (
    <CtaIllustration className={styles.wrapper}>
      <div className={styles.svgMax}>
        {/* <SvgLine top="-235px" left="0px" flip>
          <Svg />
        </SvgLine> */}
        {/* <SvgLine top="165px" right="80px">
          <SvgSecond />
        </SvgLine> */}
        <div className={styles.ctaIllustrationInner}>
          <div className={styles.textContainer}>
            <CtaText
              ctaTitle={
                data.firstHomeIllustrationCtaBanner.ctaTextIllustration.title
              }
              ctaSubTitle={
                data.firstHomeIllustrationCtaBanner.ctaTextIllustration.subTitle
              }
              ctaText={
                data.firstHomeIllustrationCtaBanner.ctaTextIllustration.ctaText
              }
              className={styles.TextWrapper}
            >
              {data.firstHomeIllustrationCtaBanner.ctaTextIllustration.ctaButtons.map(
                button => {
                  return <MyLink className="keyline-white" {...button} />
                }
              )}
            </CtaText>
          </div>
          <SideImage
            ContainerClass={styles.ImageContainer}
            alt={data.firstHomeIllustrationCtaBanner.ctaIllustration.alt}
            fluid={
              data.firstHomeIllustrationCtaBanner.ctaIllustration.asset
                .gatsbyImageData
            }
          />
        </div>
      </div>
      {/* <div className={styles.bottomWave} /> */}
      {/* <StyledSVG width="100%">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 150"
          preserveAspectRatio="none"
        >
          <path
            d="M0,96L60,90.7C120,85,240,75,360,85.3C480,96,600,128,720,138.7C840,149,960,139,1080,112C1200,85,1320,43,1380,21.3L1440,0L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            className="shape-fill"
            fill="#fff"
          ></path>
        </svg>
      </StyledSVG> */}
    </CtaIllustration>
  )
}

export default FirstCtaIllustration
