// extracted by mini-css-extract-plugin
export var animSvg = "podCards-module--animSvg--fl0IQ";
export var button = "podCards-module--button--wyIoK";
export var button_blue_ghost = "podCards-module--button_blue_ghost---X9TD";
export var customLink = "podCards-module--custom-link--NrTg5";
export var full_green_button = "podCards-module--full_green_button--YwiPt";
export var grid_block = "podCards-module--grid_block--FQML1";
export var image = "podCards-module--image--WjbDT";
export var isStyleH1 = "podCards-module--is-style-h1--OML-r";
export var isStyleH2 = "podCards-module--is-style-h2--tvNBA";
export var isStyleH3 = "podCards-module--is-style-h3---TymI";
export var isStyleH4 = "podCards-module--is-style-h4--v3oGN";
export var isStyleH5 = "podCards-module--is-style-h5--cjUjI";
export var isStyleH6 = "podCards-module--is-style-h6--e2Y48";
export var marginRight20 = "podCards-module--margin-right-20--319pO";
export var marinLeft20 = "podCards-module--marin-left-20--P7qsf";
export var pod = "podCards-module--pod--UHfo7";
export var podTitle = "podCards-module--podTitle--smUZ+";
export var svgMax = "podCards-module--svgMax--RrLFa";
export var wrapper = "podCards-module--wrapper--8lqvN";