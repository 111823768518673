import React from "react"
import * as styles from "./styles.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// Import Cta Buttons
import CtaButton from "../../global/ctaIllustration/CtaButton"
import MyLink from "../../myLink"

import SvgLine from "../../reusables/svgLine"
import { ReactComponent as Svg } from "./line9.svg"

// Page builder tyle array of blocks
const CtaPhotoCards = () => {
  // Data
  const { sanityFrontPage: data } = useStaticQuery(graphql`
    query MyLQuery {
      sanityFrontPage {
        dynamicHomePageBanners {
          backgroundColorCtaText {
            hex
          }
          backgroundColorCtaTextChoose
          bannerLayout
          bannerPhoto {
            _key
            _type
            alt
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          ctaTextPhoto {
            ctaText
            subTitle
            title
            ctaButtons {
              ... on SanityLink {
                _key
                _type
                internallink
                linktext
                newwindow
                url
              }
            }
          }
        }
      }
    }
  `)

  // Functional Components
  function CtaPhotoBox({ children }) {
    return <div>{children}</div>
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.svgMax}>
        {/* <SvgLine top="-100px" left="0px">
          <Svg/>
        </SvgLine> */}
        <CtaPhotoBox>
          {data.dynamicHomePageBanners.map(banners => {
            return (
              <div
                className={styles.wrapper_inner}
                style={{
                  backgroundColor: banners.backgroundColorCtaTextChoose,
                  flexDirection: banners.bannerLayout,
                }}
              >
                <div className={styles.textCont}>
                  <div className={styles.textInner}>
                    <h2>{banners.ctaTextPhoto.title}</h2>
                    <p className={styles.subtitle}>
                      {banners.ctaTextPhoto.subTitle
                        ? banners.ctaTextPhoto.subTitle
                        : ""}
                    </p>
                    <p>
                      {banners.ctaTextPhoto.ctaText
                        ? banners.ctaTextPhoto.ctaText
                        : ""}
                    </p>
                    {banners.ctaTextPhoto.ctaButtons.map(button => {
                      return <MyLink {...button} className={styles.button} />
                    })}
                  </div>
                </div>
                <div className={styles.imgCont}>
                  {/* {banners.ctaTextPhoto.ctaButtons.linkText} */}
                  <GatsbyImage
                    image={banners.bannerPhoto.asset.gatsbyImageData}
                    alt="banner image"
                  />
                </div>
              </div>
            )
          })}
        </CtaPhotoBox>
      </div>
    </div>
  )
}

export default CtaPhotoCards
