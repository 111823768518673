// extracted by mini-css-extract-plugin
export var ImageContainer = "drawing-module--ImageContainer--zDtQk";
export var button = "drawing-module--button--466P2";
export var buttonWrapper = "drawing-module--buttonWrapper--zs+BN";
export var ctaIllustrationInner = "drawing-module--ctaIllustrationInner--QLHQ5";
export var isStyleH1 = "drawing-module--is-style-h1--Yh8IO";
export var isStyleH2 = "drawing-module--is-style-h2--3HBen";
export var isStyleH3 = "drawing-module--is-style-h3--m16ed";
export var isStyleH4 = "drawing-module--is-style-h4--72KFS";
export var isStyleH5 = "drawing-module--is-style-h5--B+Qzh";
export var isStyleH6 = "drawing-module--is-style-h6--uZaHM";
export var link = "drawing-module--link--5-Pbw";
export var textContainer = "drawing-module--textContainer--Zs7fl";
export var wrapper = "drawing-module--wrapper--MW6bv";