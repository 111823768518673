import React from "react"

import { SanityHero } from "@graphql-types"
import { Container } from "@util/standard"
import BlocksContent from "./blocksContent"

import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Link from "./link"
import { buttonStyle, MOBILE_BREAKPOINT } from "@util/constants"

interface Props {
  hero?: SanityHero
}

const StyledHeroImage = styled(GatsbyImage)`
  width: 100%;
`

const StyledHero = styled(Container)<{
  startHex?: string
  endHex?: string
}>`
  h1 {
    font-size: var(--font-xxl-heading-desktop);
    color: #fff;
  }
  h2 {
    font-size: 30px;
  }

  p {
    color: white;
  }

  a > p {
    font-weight: 600;
    font-family: "red hat display bold";
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .hero-container {
    padding-top: 88px;

    @media only screen and (max-width: 1200px) {
      padding-top: 0px;
      margin-top: -10px;
    }
  }

  .hero-image {

  }

  .image-side-container {
    aspect-ratio: 912/740;

  }

  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    ${({ startHex, endHex }) =>
      startHex &&
      endHex &&
      `background: linear-gradient(95.93deg,${startHex}, ${endHex});`};
  }

  svg {
    position: relative;
    display: block;
    width: calc(131% + 1.3px);
    height: 177px;
  }

  path.shape-fill {
    fill: #E3F5F7;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .wave {
      display: none;
    }

    h1 {
      font-size: var(--font-xxl-heading-mobile);
      text-align: center;
    }
    p {
      text-align: center;
    }

    .hero-container {
      min-height: unset;
    }

    .image-side-container {
      margin-left: 30px;
    }
  }
`

const StyledSVG = styled(Container)`
  ${({ startHex, endHex }) =>
    startHex &&
    endHex &&
    `background: linear-gradient(95.93deg,${startHex}, ${endHex});`};

  margin-top: -20px;

  svg {
    width: 100%;
  }

  path.shape-fill {
    fill: #E3F5F7;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 60px;
  }
`

const Hero = (props: Props) => {
  const { hero } = props

  return (
    <Container flexDirection="column" position="relative">
      <StyledHero
        width="100%"
        height="auto"
        tabletHeight="auto"
        isTabletColumn
        padding="0px 0px 0px 0px"
        mobilePadding="0px"
        startHex={hero?.startHex}
        endHex={hero?.endHex}
        customBackgroundColour={hero?.startHex}
        position="relative"
        tabletHeight="auto"
        tabletPadding="0px"
      >
        <Container
          width="90%"
          height="100%"
          margin="auto"
          maxWidth="1440px"
          isTabletColumn
          tabletFlexDirection="column-reverse"
          padding="0px 0px 0px 0px"
          className="hero-container"
        >
          <Container width="50%" mobileWidth="95%" tabletWidth="100%">
            <Container
              width="70%"
              margin="auto"
              flexDirection="column"
              mobileWidth="100%"
              padding="30px 0px"
            >
              <BlocksContent data={hero?.richText} />

              <Container
                tabletMargin="0px 0px 40px 0px"
                tabletJustifyContent="center"
              >
                {hero?.ctaButtons &&
                  hero.ctaButtons.map(link => (
                    <Link
                      kind={link?.kind}
                      buttonDisplay={link?.buttonDisplay}
                      linkDisplay={link?.linkDisplay}
                      linktext={link?.linktext}
                      internallink={link?.internallink}
                      url={link?.url}
                      padding="5px 16px"
                      theme={buttonStyle[link?.buttonDisplay]}
                      key={link._key}
                    />
                  ))}
              </Container>
            </Container>
          </Container>
          <Container
            width="50%"
            mobileWidth="100%"
            tabletWidth="80%"
            mobileMargin="0px auto 30px"
            className="image-side-container"
          >
            <Container width="100%" margin="0px auto">
              <StyledHeroImage
                image={hero?.heroImage?.asset?.gatsbyImageData}
                alt=""
                position="absolute"
                top="0px"
                className="hero-image"
              />
            </Container>
          </Container>
        </Container>
      </StyledHero>
      {/* <StyledSVG width="100%" startHex={hero?.startHex} endHex={hero?.endHex}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 45 1200 100"
          preserveAspectRatio="none"
          className="wave"
        >
          <path
            d="M0,96L60,90.7C120,85,240,75,360,85.3C480,96,600,128,720,138.7C840,149,960,139,1080,112C1200,85,1320,43,1380,21.3L1440,0L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            className="shape-fill"
          ></path>
        </svg>
      </StyledSVG> */}
    </Container>
  )
}

export default Hero
