import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// Functional CTa Illustration Comp
import CtaIllustration from "../../global/ctaIllustration"
import CtaButton from "../../global/ctaIllustration/CtaButton"
import CtaText from "../../global/ctaIllustration/CtaText"
import SideImage from "../../global/ctaIllustration/SideImage"

// Generate unique keys for list items and items in array
import { v4 as uuid } from "uuid"

import SvgLine from "../../reusables/svgLine"
import { ReactComponent as Svg } from "./line8.svg"

import MyLink from "../../myLink"

// Styles
import * as styles from "./drawing.module.scss"

const SecondCtaIllustration = () => {
  // Data
  const { sanityFrontPage: data } = useStaticQuery(graphql`
    query SecondDrawingCtaQuery {
      sanityFrontPage {
        secondHomeIllustrationCtaBanner {
          backgroundColorIllustrationBannerChoose
          ctaIllustration {
            alt
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          ctaTextIllustration {
            ctaText
            title
            subTitle
            ctaButtons {
              ... on SanityLink {
                _key
                _type
                internallink
                kind
                linktext
                newwindow
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <CtaIllustration className={styles.wrapper}>
      <div className={styles.ctaIllustrationInner}>
        {/* <SvgLine top="180px" right="-150px">
          <Svg/>
        </SvgLine> */}
        <SideImage
          ContainerClass={styles.ImageContainer}
          alt={data.secondHomeIllustrationCtaBanner.ctaIllustration.alt}
          fluid={
            data.secondHomeIllustrationCtaBanner.ctaIllustration.asset
              .gatsbyImageData
          }
        />
        <div className={styles.textContainer}>
          <CtaText
            key={uuid()}
            ctaTitle={
              data.secondHomeIllustrationCtaBanner.ctaTextIllustration.title
            }
            ctaSubTitle={
              data.secondHomeIllustrationCtaBanner.ctaTextIllustration.subTitle
            }
            ctaText={
              data.secondHomeIllustrationCtaBanner.ctaTextIllustration.ctaText
            }
            className={styles.TextWrapper}
          >
            {data.secondHomeIllustrationCtaBanner.ctaTextIllustration.ctaButtons.map(
              button => {
                return <MyLink {...button} />
              }
            )}
          </CtaText>
        </div>
      </div>
    </CtaIllustration>
  )
}

export default SecondCtaIllustration
