import React, { useContext, useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as splitSectionStyles from "./splitSection.module.scss"
import { ReactComponent as Svg } from "./actiosntepsvg.svg"
import GrowthSvg from "../../growthSVG"

import MyLink from "../../myLink"
import SvgLine from "../../reusables/svgLine"

import { LocationsContext } from "../../../contexts/locationsContext"

// Animations

const SplitImagePromo = () => {
  const { currentLocation } = useContext(LocationsContext)

  // data
  const {
    allSanityFrontPage: {
      edges: [{ node: data }],
    },
  } = useStaticQuery(graphql`
    query {
      allSanityFrontPage {
        edges {
          node {
            splitPromoHomeSections {
              promoImage {
                alt
                asset {
                  url
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP]
                  )
                }
              }
              splitPanelHomeCta {
                _type
                ctaText
                subTitle
                title
                ctaButtons {
                  ... on SanityLink {
                    _key
                    _type
                    internallink
                    kind
                    linktext
                    newwindow
                    url
                  }
                }
              }
              backgroundColor {
                hex
              }
              backgroundColorChoose
              region {
                ...sanityRegion
              }
            }
            title
          }
        }
      }
    }
  `)

  const [integrations, setIntegrations] = useState(data.splitPromoHomeSections)

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(data.splitPromoHomeSections)
    } else {
      const filteredData = data.splitPromoHomeSections.filter(
        integrations =>
          integrations.region &&
          integrations.region !== null && // prevent error, if it's null, return false
          integrations.region.countryCode === currentLocation.countryCode // chech if the country code same as current country code, then return true
      )
      if (filteredData.length > 0) {
        setIntegrations(filteredData)
      } else {
        setIntegrations(data.splitPromoHomeSections)
      }
    }
  }, [currentLocation])

  const section = integrations[0]

  if (section == undefined) return null

  const backgroundColor = section.backgroundColorChoose

  return (
    <div className={splitSectionStyles.fullWidth}>
      <div className={splitSectionStyles.svgMax}>
        <div className={splitSectionStyles.container}>
          <GatsbyImage
            image={section.promoImage.asset.gatsbyImageData}
            imgStyle={{
              objectPosition: "bottom bottom",
            }}
            alt={section.promoImage.asset.alt}
          />
          <div className={splitSectionStyles.text_block}>
             <p
                className={
                  section.splitPanelHomeCta.subTitle && splitSectionStyles.text
                }
              >
                {section.splitPanelHomeCta.subTitle}
            </p>
            <p className={splitSectionStyles.title}>
              {section.splitPanelHomeCta.title}
            </p>
            <p className={splitSectionStyles.text}>
              {section.splitPanelHomeCta.ctaText}

              {section.splitPanelHomeCta.ctaButtons.map(button => {
                return <MyLink {...button} />
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitImagePromo
