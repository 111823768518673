import React from "react"
import styled from "styled-components"

const CustomSvg = styled.div`
  position: absolute;
  ${({ width }) => width && `width: ${width};`};
  ${({ top }) => top && `top: ${top};`};
  ${({ bottom }) => bottom && `bottom: ${bottom};`};
  ${({ left }) => left && `left: ${left};`};
  ${({ right }) => right && `right: ${right};`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`};
  ${({ flip }) =>
    flip &&
    `transform="scale(-1,1)"
`};

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

function SvgLine(props) {
  const {
    children,
    width,
    top,
    bottom,
    left,
    right,
    className,
    zIndex,
    flip,
  } = props

  return (
    <CustomSvg
      width={width}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      className={className}
      zIndex={zIndex}
      flip={flip}
    >
      {children}
    </CustomSvg>
  )
}

export default SvgLine
