// extracted by mini-css-extract-plugin
export var animSvg = "styles-module--animSvg--2o8V3";
export var button = "styles-module--button--jP39L";
export var imgCont = "styles-module--imgCont--Ok2hc";
export var isStyleH1 = "styles-module--is-style-h1--hCb7Y";
export var isStyleH2 = "styles-module--is-style-h2--tp8A+";
export var isStyleH3 = "styles-module--is-style-h3--a6vBy";
export var isStyleH4 = "styles-module--is-style-h4--PpAx2";
export var isStyleH5 = "styles-module--is-style-h5--u2aBv";
export var isStyleH6 = "styles-module--is-style-h6--2iIqo";
export var link = "styles-module--link--BIGbA";
export var subtitle = "styles-module--subtitle--eE7Gp";
export var svgMax = "styles-module--svgMax--KlGIh";
export var textCont = "styles-module--textCont--skdoq";
export var wrapper = "styles-module--wrapper--OLa7n";
export var wrapper_inner = "styles-module--wrapper_inner--MUiBp";