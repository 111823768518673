// extracted by mini-css-extract-plugin
export var ImageContainer = "drawing-module--ImageContainer--5zfnd";
export var animSvg = "drawing-module--animSvg--vLemk";
export var bottomWave = "drawing-module--bottomWave--EKpHv";
export var button = "drawing-module--button--isDTX";
export var buttonWrapper = "drawing-module--buttonWrapper--k2pIm";
export var ctaIllustrationInner = "drawing-module--ctaIllustrationInner--dZhTG";
export var isStyleH1 = "drawing-module--is-style-h1--lkGAK";
export var isStyleH2 = "drawing-module--is-style-h2--Hvuon";
export var isStyleH3 = "drawing-module--is-style-h3--mRsl3";
export var isStyleH4 = "drawing-module--is-style-h4--QkoNA";
export var isStyleH5 = "drawing-module--is-style-h5--3Luyp";
export var isStyleH6 = "drawing-module--is-style-h6--xZPcd";
export var link = "drawing-module--link--bOB56";
export var svgMax = "drawing-module--svgMax--VgauU";
export var textContainer = "drawing-module--textContainer--M-LGl";
export var wrapper = "drawing-module--wrapper--iORh5";