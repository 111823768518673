import { useStaticQuery, Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { ReactComponent as Svg } from "./third.svg"
import MyLink from "../../../components/myLink"

//Style
import * as podStyles from "./podCards.module.scss"
import SvgLine from "../../reusables/svgLine"

const Podcards = () => {
  const { sanityFrontPage: data } = useStaticQuery(graphql`
    query MyQuery {
      sanityFrontPage {
        podCardGrid {
          _key
          _type
          podTitle
          podText
          podImage {
            _key
            _type
            caption
            attribution
            alt
            asset {
              url
              gatsbyImageData(
                width: 72
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          podButton {
            linktext
            newwindow
            url
            internallink
          }
          colorlist
        }
      }
    }
  `)

  return (
    <div className={podStyles.wrapper}>
      <div className={podStyles.svgMax}>
        {/* <Svg className={podStyles.animSvg} /> */}
        <div className={podStyles.grid_block}>
          {data.podCardGrid.map(item => {
            return (
              <div className={podStyles.pod} key={item._id}>
                <p className={podStyles.podTitle}>{item?.podTitle}</p>
                <GatsbyImage
                  image={item?.podImage?.asset?.gatsbyImageData}
                  classname={podStyles?.image}
                  alt="Action Step Footer CTA Illustration"
                  className={podStyles.image}
                />
                <p className={podStyles.small_text}>{item.podText}</p>
                {item.podButton.url ? (
                  <MyLink {...item.podButton} className={podStyles.button} />
                ) : null}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Podcards
