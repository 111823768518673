import React, { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import "./featuredLogos.sass"

SwiperCore.use([Navigation, Autoplay])

const FeaturedLogos = ({ data }) => {
  const [width, setWidth] = useState(900)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    })
    setWidth(window.innerWidth)
  }, [])

  return (
    <div className="featured-logos">
      <div className="container">
        <h3>{data.title || ""}</h3>
        <div className="slide-container">
          <Swiper
            className="swiper"
            spaceBetween={50}
            slidesPerView={
              (width > 900 && 5) ||
              (width > 600 && 4) ||
              (width > 400 && 3) ||
              (width <= 400 && 2)
            }
            navigation={{ nextEl: ".right", prevEl: ".left" }}
            loop={true}
            autoplay={true}
          >
            {data.logos.map((logo, index) => (
              <SwiperSlide key={index}>
                {logo.linkPath ? (
                  <a href={logo.linkPath} target="_blank">
                    <GatsbyImage
                      image={logo.logoImage.asset?.gatsbyImageData}
                      alt={logo.logoImage.asset?.originalFilename}
                    />
                  </a>
                ) : (
                  logo.logoImage && (
                    <GatsbyImage
                      image={logo.logoImage.asset?.gatsbyImageData}
                      alt={logo.logoImage.asset?.originalFilename}
                    />
                  )
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="nav-button left">
            <MdKeyboardArrowLeft />
          </div>
          <div className="nav-button right">
            <MdKeyboardArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedLogos
