import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import "./blogsFeed.sass"

import SvgLine from "../reusables/svgLine"

import { ReactComponent as Svg } from "./line10.svg"

SwiperCore.use([Navigation, Autoplay])

const BlogsFeed = () => {
  const [width, setWidth] = useState(900)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    })
    setWidth(window.innerWidth)
  }, [])

  const {
    sanityFrontPage: { blogReference: data },
  } = useStaticQuery(graphql`
    {
      sanityFrontPage {
        blogReference {
          _key
          title
          references {
            _key
            featureImage {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            title
            excerpt
            slug {
              current
            }
          }
        }
      }
    }
  `)

  return (
    <div className="blogs-feed">
      <SvgLine top="-350px" right="135px" zIndex={-1} className="blog-svg">
        <Svg />
      </SvgLine>
      <div className="container">
        <h3>{data.title}</h3>
        <div className="slider-container">
          <Swiper
            className="blog-slider"
            spaceBetween={(width > 800 && 50) || (width <= 800 && 30)}
            navigation={{ nextEl: ".right", prevEl: ".left" }}
            slidesPerView={(width > 800 && 2) || (width <= 800 && 1)}
            loop={true}
            autoplay={false}
            key={data._key}
          >
            {data.references.map(blog => (
              <SwiperSlide key={blog.title} className="blog">
                <GatsbyImage
                  image={blog.featureImage.asset.gatsbyImageData}
                  alt={blog.featureImage.asset.originalFilename}
                />
                {/* <h5>Product Update</h5> */}
                <h3>{blog.title}</h3>
                <p>{blog.excerpt}</p>
                <Link to={`/blog/${blog.slug.current}/`}>Read More</Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="nav-button left">
            <MdKeyboardArrowLeft />
          </div>
          <div className="nav-button right">
            <MdKeyboardArrowRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogsFeed
