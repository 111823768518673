// extracted by mini-css-extract-plugin
export var animSvg = "splitSection-module--animSvg--oP3Pk";
export var button = "splitSection-module--button--ML3p9";
export var container = "splitSection-module--container---GUFc";
export var image_block = "splitSection-module--image_block--ZRllu";
export var isStyleH1 = "splitSection-module--is-style-h1--T+FOK";
export var isStyleH2 = "splitSection-module--is-style-h2--9FWlr";
export var isStyleH3 = "splitSection-module--is-style-h3--ruFHk";
export var isStyleH4 = "splitSection-module--is-style-h4--N8TK8";
export var isStyleH5 = "splitSection-module--is-style-h5--m5k+n";
export var isStyleH6 = "splitSection-module--is-style-h6--Rvuxz";
export var link = "splitSection-module--link--N5Lw9";
export var svgMax = "splitSection-module--svgMax--tOm8S";
export var text = "splitSection-module--text--J3324";
export var text_block = "splitSection-module--text_block--lQzQQ";
export var title = "splitSection-module--title--RSYDB";