// extracted by mini-css-extract-plugin
export var animSvg = "styles-module--animSvg--ylgly";
export var button = "styles-module--button--u58JU";
export var contentWrapper = "styles-module--contentWrapper--crYcq";
export var imgCont = "styles-module--imgCont--bNe0b";
export var isStyleH1 = "styles-module--is-style-h1--qjuhW";
export var isStyleH2 = "styles-module--is-style-h2--Xb8gx";
export var isStyleH3 = "styles-module--is-style-h3--o2dvV";
export var isStyleH4 = "styles-module--is-style-h4--bcgtD";
export var isStyleH5 = "styles-module--is-style-h5--aXtIz";
export var isStyleH6 = "styles-module--is-style-h6--76+FF";
export var link = "styles-module--link--9bcnE";
export var playButton = "styles-module--playButton--7Wsf1";
export var rightSvg = "styles-module--rightSvg--kjVr6";
export var scrollingSVG = "styles-module--scrollingSVG---TfFZ";
export var svgMax = "styles-module--svgMax--id+0K";
export var textCont = "styles-module--textCont--CtE6f";
export var wrapper = "styles-module--wrapper--yw14t";
export var wrapper_inner = "styles-module--wrapper_inner--igbyp";